export enum UsersLoaderKey {
  GET_USER = 'get-user',
  LIST_USERS = 'list-users',
  SAVE_USER = 'save-user',
  DELETE_USER = 'delete_user',
  ATTACH_USER_GROUP = 'attach-user-group',
  DETACH_USER_GROUP = 'detach-user-group',
}

export enum UserType {
  'Super' = 0,
  'Internal' = 1,
  'Guest' = 2,
}
