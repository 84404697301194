import { Exclude, Expose } from 'class-transformer';

import { PERMISSIONS } from '@core/constants';
import { Permission, UserType } from '@core/enums';
import { Avatar, IPermission, IUserWithPermissions, UserDetailsPermissionsList, UserGroup } from '@core/models';
import { permissionKeyToHumanValue } from '@core/utils';

@Exclude()
export class UserWithPermissions implements IUserWithPermissions {
  @Expose()
    id!: string;

  @Expose()
    name!: string;

  @Expose()
    email!: string;

  @Expose()
    avatar!: Avatar;

  @Expose()
    permissions!: Permission[];

  @Expose()
    groups!: UserGroup[];

  @Expose()
    type!: number;

  @Expose()
    updatedAt!: string | null;

  @Expose()
    createdAt!: string;

  @Expose()
    registeredAt!: string;

  get avatarInitials(): string {
    return this.avatar?.initials?.toUpperCase() ?? '-';
  }

  get avatarColor(): string {
    return this.avatar.bgColor;
  }

  get userPermissionList(): UserDetailsPermissionsList[] {
    return this.permissions
      .map((permission: Permission) => PERMISSIONS.find((perm: IPermission): boolean => perm.key === permission))
      .filter((permission?: IPermission) => permission)
      .map((permission?: IPermission): UserDetailsPermissionsList => {
        return {
          key: permissionKeyToHumanValue(permission?.key ?? ''),
          desc: permission?.desc,
        };
      });
  }

  get isInternalUserType(): boolean {
    return this.type === UserType.Internal;
  }
}
