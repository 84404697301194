export interface AuditLog {
  id: string;
  customerId: string;
  userId: string;
  category: string;
  subcategory: string;
  event: string;
  // entity payload, can be any entity
  entity: object;
  message: Message;
  change: Change;
  createdAt: Date;
}

export interface AuditLogFilter {
  createdAt: Calendar;
  users: User[];
}

export interface AuditLogFilters {
  userId?: string;
  category?: string;
  event?: string;
  startDate?: string | null;
  endDate?: string | null;
}

interface Change {
  attributes: [];
  relations: [];
}

interface Message {
  value: string;
  link: string | null;
}

interface Calendar {
  min: string;
  max: string;
}

interface User {
  id: string;
  name: string;
}
