import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { TransactionLoaderKey } from '../enums';
import {
  PaginatedTransactions,
  Transaction,
  TransactionFilter,
  TransactionFilters,
  TransactionMetrics,
} from '../models';
import { ApiService, LoadingService, SnackbarService } from '../services';

const ENDPOINT = 'v1/transactions';

@Injectable({ providedIn: 'root' })
export class TransactionsService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<Transaction[]> {
    return this.api
      .get<Transaction[]>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set(TransactionLoaderKey.GET_TRANSACTION), this.snack.operator());
  }

  metrics(): Observable<TransactionMetrics> {
    return this.api
      .get<TransactionMetrics>(`${ENDPOINT}/metrics`)
      .pipe(first(), this.loader.set(TransactionLoaderKey.GET_TRANSACTION_METRICS), this.snack.operator());
  }

  recent(): Observable<Transaction[]> {
    return this.api
      .get<Transaction[]>(`${ENDPOINT}/recent`)
      .pipe(first(), this.loader.set(TransactionLoaderKey.GET_RECENT_TRANSACTION), this.snack.operator());
  }

  filters(): Observable<TransactionFilters> {
    return this.api.get<TransactionFilters>(`${ENDPOINT}/filters`).pipe(first());
  }

  list(page: number, limit: number, filter: TransactionFilter): Observable<PaginatedTransactions> {
    const params = new HttpParams({ fromObject: { page: page + 1, limit, ...filter } });

    return this.api
      .get<PaginatedTransactions>(`${ENDPOINT}`, { params })
      .pipe(first(), this.loader.set(TransactionLoaderKey.LIST_TRANSACTION), this.snack.operator());
  }

  preview(id: string): Observable<string> {
    return this.api
      .get<string>(`${ENDPOINT}/${id}/preview`, { responseType: 'text' })
      .pipe(first(), this.loader.set(TransactionLoaderKey.GET_TRANSACTION_PREVIEW), this.snack.operator());
  }
}
