import { Exclude, Expose } from 'class-transformer';

import { dataCollectionHistoryStatusMap } from '@core/constants';
import { DataCollectionTemplateId, ReportingType, UploadStatus } from '@core/enums';
import { IDataCollection } from '@core/models';

import { Badge } from '../badge';

@Exclude()
export class DataCollection implements IDataCollection {
  @Expose()
    id!: string;

  @Expose()
    reportingDate!: string;

  @Expose()
    filename!: string | null;

  @Expose()
    userName!: string;

  @Expose()
    status!: UploadStatus;

  @Expose()
    type!: ReportingType;

  @Expose()
    createdAt!: string;

  @Expose()
    error!: string[] | null;

  @Expose()
    templateId!: string | null;

  @Expose()
    originalStatus!: UploadStatus;

  @Expose()
    canDownload?: boolean;

  @Expose()
    warehouseName?: string;

  @Expose()
    warehouseId?: string | null;

  get statusBadge(): Badge<UploadStatus> {
    return dataCollectionHistoryStatusMap[this.status];
  }

  get canHandleFailedFile(): boolean {
    return (
      this.originalStatus === UploadStatus.CALCULATION_FAILED &&
      this.templateId === DataCollectionTemplateId.TRANSPORT_6
    );
  }
}
