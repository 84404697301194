import { Exclude, Expose } from 'class-transformer';

import { UserType } from '@core/enums';
import { Avatar, IUserWithPartner, TradingPartner } from '@core/models';

@Exclude()
export class UserWithPartner implements IUserWithPartner {
  @Expose()
    name!: string;

  @Expose()
    email!: string;

  @Expose()
    type!: number;

  @Expose()
    partner!: TradingPartner | null;

  @Expose()
    createdAt!: string;

  @Expose()
    registeredAt!: string;

  @Expose()
    id!: string;

  @Expose()
    avatar!: Avatar;

  @Expose()
    updatedAt!: string | null;

  get tradingPartnerName(): string {
    return this.partner?.name ?? '';
  }

  get userType(): string {
    return UserType[this.type];
  }
}
