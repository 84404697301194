export const environment = {
  production: false,
  networkIds: {
    1: 'https://goerli.etherscan.io/tx/', //ethereum(1)
    2: 'https://goerli.arbiscan.io/tx/', //arbitrum(2)
    3: '' //@TODO add fabric tx receipt link for dev
  },
  auth0: {
    domain: 'https://dev.login.veratrak.com',
    audience: 'dev-veratrak.eu.auth0.com',
    clientId: 'BpnbG77ItuedhH0WszoF0j1G6az43iNu'
  },
  vapid: 'BFPVeUV_hMp-Pe4fv7IUfpQSoQrdMy2zaSjzQRsUEPezTusdsldFSnBJrnGR4-61b8rV2Y9d5bioTMw1rc6gshY',
  apiHost: 'https://api.dev.hub.veratrak.com',
  socketHost: 'wss://socket.dev.hub.veratrak.com',
  jsonCrackHost: 'https://jsoncrack.dev.hub.veratrak.com',
  jsoncrack: 'https://jsoncrack.dev.hub.veratrak.com/widget',
  gtm: 'GTM-T7B4X69'
};
