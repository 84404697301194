export enum WarehouseStatus {
  NEW = 'NEW',
  PRE_ONBOARDED = 'PRE_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  INACTIVE = 'INACTIVE',
}

export enum WarehouseReportingStatus {
  REPORTED = 'REPORTED',
  PARTIALLY_REPORTED = 'PARTIALLY_REPORTED',
  NOT_REPORTED = 'NOT_REPORTED',
}

export enum WarehouseLoaderKey {
  LIST_WAREHOUSES = 'list-warehouses',
  CREATE_WAREHOUSE = 'create-warehouse',
  UPDATE_WAREHOUSE = 'update-warehouse',
  DELETE_WAREHOUSE = 'delete-warehouse',
  LIST_WAREHOUSE_CONTACTS = 'list-warehouse-contacts',
  ADD_WAREHOUSE_CONTACT = 'add-warehouse-contact',
  CREATE_WAREHOUSE_CONTACT = 'create-warehouse-contact',
  UPDATE_WAREHOUSE_CONTACT = 'update-warehouse-contact',
  DELETE_WAREHOUSE_CONTACT = 'delete-warehouse-contact',
  LIST_WAREHOUSE_HISTORY = 'list-warehouse-history',
  LIST_DASHBOARD_WAREHOUSES = 'list-dashboard-warehouses',
}
