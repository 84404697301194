import { Badge } from '@shared/badge';

import { MeasurementSystem, WarehouseReportingStatus, WarehouseStatus } from '../enums';
import { ExtraParams, RequestParams } from '../models';

export interface Warehouse {
  id: string;
  name: string;
  contacts: string;
  createdAt: string;
  updatedAt: string;
  country: string;
  city: string;
  state?: string;
  address: string;
  postCode: string;
  partnerId: string;
  partnerName: string;
  status: WarehouseStatus;
  statusBadge: Badge<WarehouseStatus>;
  reportingStatus: WarehouseReportingStatus;
  reportingStatusBadge: Badge<WarehouseReportingStatus>;
  plantCode: string;
  measurementSystem: MeasurementSystem;
  transportEmissionsTemplateId: string;
  warehouseEmissionsTemplateId: string;
}

export interface WarehouseContact {
  id: string;
  registeredAt: string | null;
  fullName: string;
  email: string;
  userId: string;
  canUseWarehouseTemplate: boolean;
  canUseTransportTemplate: boolean;
  templateNames?: string;
}

export interface WarehouseContactDeleteParams {
  id: string;
  warehouseId: string;
}

export interface WarehouseContactPayload {
  warehouseId: string;
  canUseWarehouseTemplate: boolean;
  canUseTransportTemplate: boolean;
}

export interface IWarehouseHistory {
  createdAt: string;
  fullName: string;
  email: string;
  description: string;
}

export type WarehouseListExtraParams = ExtraParams<WarehouseStatus>;
export type DashboardWarehousesExtraParams = ExtraParams<WarehouseStatus> & {
  reportingStartDate: string;
  reportingEndDate: string;
  reportingStatus?: WarehouseReportingStatus;
};

export type EditWarehouseContactPayload = WarehouseContactPayload & { contactId: string };
export type AddWarehouseContactPayload = WarehouseContactPayload & { userId: string };
export type CreateWarehouseContactPayload = WarehouseContactPayload & { email: string };

export type WarehouseContactsParams = RequestParams<WarehouseContact> & { warehouseId: string };
export type WarehouseHistoryParams = RequestParams<IWarehouseHistory> & { warehouseId: string };
export type WarehouseListParams = RequestParams<Warehouse> & {
  extraParams?: WarehouseListExtraParams;
};
export type DashboardWarehousesListParams = RequestParams<Warehouse> & {
  extraParams?: DashboardWarehousesExtraParams;
};
