export interface Transaction {
  id: string;
  createdAt: Date;
  transactionId: string;
  transactionType: string;
  senderId: string;
  receiverId: string;
  status: boolean;
  statusCode: string;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  s3Ref?: any;
}

export interface PaginatedTransactions {
  records: Transaction[];
  _meta: { total: number };
}

export interface S3Ref {
  bucket: string;
  prefix: string;
  key: string;
  eTag: string;
  type: string;
}

export interface TransactionMetrics {
  total: number;
  totalMonth: number;
  errorMonth: number;
  errorTotal: number;
}

export interface TransactionFilters {
  createdAt: {
    min?: Date;
    max?: Date;
  };
  senders: string[];
  receivers: string[];
  types: string[];
}
