/**
 * @see https://veratrak.atlassian.net/wiki/spaces/IWP/pages/81985541/Veratrak+Standards
 */
export type TransactionType = 'TRANSPORT' | 'WHSEIB' | 'WHSEOB' | 'STORMAT' | 'STORCOMM' | 'ABCSUM';

export enum TransactionTypes {
  WHSEIB = 'Warehouse inbound',
  WHSEOB = 'Warehouse outbound',
  STORMAT = 'Storage materials',
  ABCSUM = 'Activity-based costing',
  MINIQUERY = 'Transport miniquery',
  MANIFEST = 'Transport manifest',
}
