export enum MeasurementSystem {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export enum MeasurementSystemCategory {
  DISTANCE = 'DISTANCE',
  WEIGHT = 'WEIGHT',
}

export enum UOMSymbol {
  KILOMETERS = 'km',
  NAUTICAL_MILES = 'nm',
  MILES = 'mi',
  TONS = 't',
  IMPERIAL_TONS = 'ton',
  KILOTONS = 'kt',
  MEGATONS = 'Mt',
  GIGATONS = 'Gt',
}
