import { Permission } from '../enums';
import { Avatar, Group, Organisation, SelectOption, TradingPartner } from '../models';

export const userTypes: Record<number, string> = {
  0: 'super',
  1: 'internal',
  2: 'guest',
};

export interface IUser {
  id: string;
  createdAt: string;
  registeredAt: string | null;
  updatedAt: string | null;
  name: string;
  email: string;
  avatar: Avatar;
  type: number;
  groups: UserGroup[];
  organisations: Organisation[];
  deletedAt?: string | null;
  new?: boolean;
  invite?: string;
  partner?: TradingPartner | null;
}

export interface IUserWithPartner {
  id: string;
  createdAt: string;
  registeredAt: string | null;
  updatedAt: string | null;
  name: string;
  email: string;
  avatar: Avatar;
  type: number;
  partner: TradingPartner | null;
}

export interface IUserWithPermissions {
  id: string;
  createdAt: string;
  registeredAt: string | null;
  updatedAt: string | null;
  name: string;
  email: string;
  avatar: Avatar;
  groups: UserGroup[];
  type: number;
  permissions: Permission[];
}

export interface ListUsersPayload {
  includeOnlyInternalUsers: boolean;
}

export type UserGroup = Omit<Group, 'users'>;
export type UserDetailsPermissionsList = Omit<SelectOption<string>, 'value'>;
