import { Exclude, Expose, Transform } from 'class-transformer';

import { DataCollectionTotalsMetrics } from '@core/models';

@Exclude()
export class DashboardTotalsMetrics implements DataCollectionTotalsMetrics {
  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    totalCo2!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    warehouseTotalCo2!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    transportTotalCo2!: number;
}
