import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { LoadingService } from '../loading';
import { SnackbarService } from '../snackbar.service';
import { Observable, first } from 'rxjs';
import { EmissionsGuestResponse, TasksResponse } from '@core/models';
import { DashboardLoaderKey } from '@core/enums';

const ENDPOINT = 'v1/dashboard';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  getGuestEmissions(): Observable<EmissionsGuestResponse> {
    return this.api
      .get<EmissionsGuestResponse>(`${ENDPOINT}/emissions`)
      .pipe(first(), this.loader.set(DashboardLoaderKey.GET_GUEST_EMISSIONS), this.snack.operator());
  }

  getTasks(): Observable<TasksResponse> {
    return this.api
      .get<TasksResponse>(`${ENDPOINT}/tasks`)
      .pipe(first(), this.loader.set(DashboardLoaderKey.GET_TASKS), this.snack.operator());
  }
}
