import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { DataCollectionLoaderKey } from '../enums';
import {
  DataCollectionMethodsMetrics,
  DataCollectionMetricsParams,
  DataCollectionNotReportedMonthList,
  DataCollectionNotReportedMonthListParams,
  DataCollectionReportingMetrics,
  DataCollectionTotalsMetrics,
  IDataCollection,
  IDataCollectionUserWarehouse,
  Response,
  UploadWarehouseDataCollectionHistoryEntriesPayload,
  UploadWarehouseDataCollectionHistoryFilePayload,
  UploadWarehouseDataCollectionHistoryResponse,
  UserDataCollectionHistoryParams,
  WarehouseDataCollectionHistoryParams,
} from '../models';
import { ApiService, LoadingService, SnackbarService } from '../services';

const ENDPOINT = 'v1/data-collection';

@Injectable({ providedIn: 'root' })
export class DataCollectionApiService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  listHistoryForUser(options: UserDataCollectionHistoryParams): Observable<Response<IDataCollection>> {
    const { page = 1, limit, sort, order, extraParams } = options;
    const data = {
      page,
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(extraParams && { ...extraParams }),
    };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<IDataCollection>>(`${ENDPOINT}/history/user`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.LIST_USER_HISTORY), this.snack.operator());
  }

  listHistoryForWarehouse(options: WarehouseDataCollectionHistoryParams): Observable<Response<IDataCollection>> {
    const { page = 1, limit, sort, order, warehouseId } = options;
    const data = { page, ...(limit && { limit }), ...(sort && { sort }), ...(order && { order }) };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<IDataCollection>>(`${ENDPOINT}/history/warehouse/${warehouseId}`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.LIST_WAREHOUSE_HISTORY), this.snack.operator());
  }

  listUserWarehouses(): Observable<IDataCollectionUserWarehouse[]> {
    return this.api
      .get<IDataCollectionUserWarehouse[]>(`${ENDPOINT}/user/warehouses`)
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.LIST_USER_WAREHOUSES), this.snack.operator());
  }

  listNotReportedMonth(
    options: DataCollectionNotReportedMonthListParams,
  ): Observable<DataCollectionNotReportedMonthList> {
    const { warehouseId, templateId } = options;
    const params = new HttpParams({ fromObject: { templateId } });

    return this.api
      .get(`${ENDPOINT}/history/warehouse/${warehouseId}/months`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.LIST_NOT_REPORTED_MONTH), this.snack.operator());
  }

  uploadFile(
    payload: UploadWarehouseDataCollectionHistoryFilePayload,
  ): Observable<UploadWarehouseDataCollectionHistoryResponse> {
    const { warehouseId, formData } = payload;

    return this.api
      .post<UploadWarehouseDataCollectionHistoryResponse, FormData>(
        `${ENDPOINT}/history/warehouses/${warehouseId}/upload`,
        formData,
      )
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.UPLOAD_WAREHOUSE_HISTORY_FILE), this.snack.operator());
  }

  uploadEntries(
    payload: UploadWarehouseDataCollectionHistoryEntriesPayload,
  ): Observable<UploadWarehouseDataCollectionHistoryResponse> {
    const { warehouseId } = payload;

    return this.api
      .post<UploadWarehouseDataCollectionHistoryResponse, UploadWarehouseDataCollectionHistoryEntriesPayload>(
        `${ENDPOINT}/history/warehouses/${warehouseId}/entries`,
        payload,
      )
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.UPLOAD_WAREHOUSE_HISTORY_ENTRIES), this.snack.operator());
  }

  getTotalsMetrics(options: DataCollectionMetricsParams): Observable<DataCollectionTotalsMetrics> {
    const data = { ...options };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<DataCollectionTotalsMetrics>(`${ENDPOINT}/history/metrics/dashboard/totals`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_TOTALS_METRICS), this.snack.operator());
  }

  getMethodsMetrics(options: DataCollectionMetricsParams): Observable<DataCollectionMethodsMetrics> {
    const data = { ...options };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<DataCollectionMethodsMetrics>(`${ENDPOINT}/history/metrics/dashboard/methods`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_METHODS_METRICS), this.snack.operator());
  }

  getReportingMetrics(options: DataCollectionMetricsParams): Observable<DataCollectionReportingMetrics> {
    const data = { ...options };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<DataCollectionReportingMetrics>(`${ENDPOINT}/history/metrics/dashboard/reporting`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_REPORTING_METRICS), this.snack.operator());
  }

  preview(id: string): Observable<string> {
    return this.api
      .get<string>(`${ENDPOINT}/${id}/preview`, { responseType: 'text' })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_PREVIEW), this.snack.operator());
  }
}
